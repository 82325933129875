import { createBrowserClient } from '@supabase/ssr'
import { useState } from 'react'

import { PUBLIC_SUPABASE_ANON_KEY, PUBLIC_SUPABASE_URL } from '../constants'

export const createClient = () => {
  return createBrowserClient(PUBLIC_SUPABASE_URL, PUBLIC_SUPABASE_ANON_KEY)
}

export function useSupabase() {
  const [supabase] = useState(() => createClient())

  return supabase
}
