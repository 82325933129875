'use client';

// https://medium.com/@ianduhamel/how-to-implement-amplitude-in-next-js-a-3-step-guide-6803c44ca862
import * as amplitude from '@amplitude/analytics-browser';
import type { BaseEvent } from '@amplitude/analytics-types';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';
import { useEffect, createContext, useContext } from 'react';
import { useUser } from '@/hooks/useUser';
import { PUBLIC_AMPLITUDE_REPLAY_SAMPLE_RATE } from './constants';
import { PUBLIC_AMPLITUDE_API_KEY } from './constants';

// Add this interface to define the context value type
interface AmplitudeContextType {
  trackAmplitudeEvent: (eventName: string | BaseEvent, eventProperties?: Record<string, any>) => void;
}

// Update the context creation with the new type
export const AmplitudeContext = createContext<AmplitudeContextType>({
  trackAmplitudeEvent: () => {} // Provide a default no-op function
});
export const AmplitudeContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({
  children
}) => {
  const {
    user
  } = useUser();
  useEffect(() => {
    if (!PUBLIC_AMPLITUDE_API_KEY) {
      return;
    }
    const sessionReplayTracking = sessionReplayPlugin({
      sampleRate: PUBLIC_AMPLITUDE_REPLAY_SAMPLE_RATE
    });
    amplitude.add(sessionReplayTracking);
    if (user) {
      amplitude.setUserId(user.id);
    }
    amplitude.init(PUBLIC_AMPLITUDE_API_KEY, undefined, {
      defaultTracking: {
        sessions: true
      }
    });
  }, [PUBLIC_AMPLITUDE_API_KEY]);
  const trackAmplitudeEvent = (eventName: string | BaseEvent, eventProperties?: Record<string, any>) => {
    if (!PUBLIC_AMPLITUDE_API_KEY) {
      console.error('Amplitude API key is not set');
      return;
    }
    amplitude.track(eventName, eventProperties);
  };
  const value: AmplitudeContextType = {
    trackAmplitudeEvent
  };
  return <AmplitudeContext.Provider value={value} data-sentry-element="unknown" data-sentry-component="AmplitudeContextProvider" data-sentry-source-file="amplitude.tsx">
      {children}
    </AmplitudeContext.Provider>;
};
export const useAmplitudeContext = (): AmplitudeContextType => {
  const context = useContext(AmplitudeContext);
  if (context === undefined) throw new Error('useAmplitudeContext must be used within a AmplitudeContextProvider');
  return context;
};