'use client';

import { GoogleAnalytics, GoogleTagManager } from '@next/third-parties/google';
import { Theme } from '@radix-ui/themes';
import { Inter as FontSans } from 'next/font/google';
import { Toaster } from 'react-hot-toast';
import { ThemeProvider } from '@/components/theme-provider';
import { ToastProvider } from '@/components/ui/toast';
import { TooltipProvider } from '@/components/ui/tooltip';
import { cn } from '@/lib/utils';
import '@/styles/globals.css';
import { AmplitudeContextProvider } from '@/utils/amplitude';
import { APIProvider, ArmsRumProvider } from '@/utils/api/provider';
import { AppEnv, PUBLIC_APP_ENV } from '@/utils/constants';
import { ReactQueryProvider } from '@/utils/useQuery';
import '@radix-ui/themes/styles.css';
const fontSans = FontSans({
  subsets: ['latin'],
  variable: '--font-sans'
});
export default function RootLayout({
  children
}: {
  children: React.ReactNode;
}): React.ReactElement {
  return <html lang="en" data-sentry-component="RootLayout" data-sentry-source-file="layout.tsx">
      <GoogleTagManager gtmId="GTM-55X6DWGK" data-sentry-element="GoogleTagManager" data-sentry-source-file="layout.tsx" />
      <GoogleAnalytics gaId="G-HJNF0Z6ZDK" data-sentry-element="GoogleAnalytics" data-sentry-source-file="layout.tsx" />
      <head>
        <title>
          {PUBLIC_APP_ENV !== AppEnv.PRODUCTION ? `${PUBLIC_APP_ENV}｜CreateAny.ai` : 'CreateAny.ai'}
        </title>
        <meta name="description" content="Build your idea/webapp/tool/game with a single line with the community together." data-sentry-element="meta" data-sentry-source-file="layout.tsx" />
        <link rel="icon" href="/favicon.ico" sizes="any" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
        <link rel="manifest" href="/site.webmanifest" />
      </head>
      <body className={cn('min-h-screen bg-background font-sans antialiased', fontSans.variable)}>
        <Toaster data-sentry-element="Toaster" data-sentry-source-file="layout.tsx" />
        <APIProvider data-sentry-element="APIProvider" data-sentry-source-file="layout.tsx">
          <ToastProvider data-sentry-element="ToastProvider" data-sentry-source-file="layout.tsx">
            <ReactQueryProvider data-sentry-element="ReactQueryProvider" data-sentry-source-file="layout.tsx">
              <ArmsRumProvider data-sentry-element="ArmsRumProvider" data-sentry-source-file="layout.tsx">
                <AmplitudeContextProvider data-sentry-element="AmplitudeContextProvider" data-sentry-source-file="layout.tsx">
                  <Theme data-sentry-element="Theme" data-sentry-source-file="layout.tsx">
                    <ThemeProvider attribute="class" defaultTheme="light" enableSystem disableTransitionOnChange data-sentry-element="ThemeProvider" data-sentry-source-file="layout.tsx">
                      <TooltipProvider data-sentry-element="TooltipProvider" data-sentry-source-file="layout.tsx">{children}</TooltipProvider>
                    </ThemeProvider>
                  </Theme>
                </AmplitudeContextProvider>
              </ArmsRumProvider>
            </ReactQueryProvider>
          </ToastProvider>
        </APIProvider>
      </body>
    </html>;
}