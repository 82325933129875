import { sendGAEvent } from '@next/third-parties/google'

function gtag_report_conversion() {
  sendGAEvent('event', 'conversion', {
    send_to: 'AW-16698377832/UREiCMGrpOQZEOiMtJo-',
    event_callback: () => {
      console.log('event_callback', 'AAAAAAAAA')
    },
  })
  return false
}

export { gtag_report_conversion }
