import { Profile } from '@repo/common/entities'
import { User } from '@supabase/supabase-js'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useCallback } from 'react'

import { gtag_report_conversion } from '@/utils/gtag'
import { CREATE_USER_INFO_STORAGE_KEY, CREATE_USER_INFO_TIME_STORAGE_KEY } from '@/utils/storage'

import { useSupabase } from '../utils/supabase/client'

export type UserWithProfile = User & {
  profile: Profile | null
}

const CACHE_KEY = CREATE_USER_INFO_STORAGE_KEY
const CACHE_TIME_KEY = CREATE_USER_INFO_TIME_STORAGE_KEY
const CACHE_DURATION = 1000 * 60 * 60 // 1小时

const REGISTER_REPORTED_KEY = 'user_register_reported' // 添加新的常量
const REGISTER_TIME_THRESHOLD = 1000 * 60 * 5 // 5分钟

function clearUserCache() {
  localStorage.removeItem(CACHE_KEY)
  localStorage.removeItem(CACHE_TIME_KEY)
}

export function useUser() {
  const supabase = useSupabase()
  const queryClient = useQueryClient()

  const fetchUser = useCallback(async (): Promise<UserWithProfile | null> => {
    const {
      data: { session },
      error: sessionError
    } = await supabase.auth.getSession()
    if (!session || sessionError) return null

    const { data: profile } = await supabase
      .from('profiles')
      .select(`id, updated_at, avatar_url, username, full_name, website`)
      .eq('id', session.user.id)
      .single()

    // 检查是否需要上报注册事件
    const userCreatedAt = new Date(session.user.created_at).getTime()
    const now = Date.now()
    const isNewUser = now - userCreatedAt < REGISTER_TIME_THRESHOLD
    const hasReported = localStorage.getItem(`${REGISTER_REPORTED_KEY}_${session.user.id}`)

    if (isNewUser && !hasReported) {
      gtag_report_conversion()
      localStorage.setItem(`${REGISTER_REPORTED_KEY}_${session.user.id}`, 'true')
    }

    return {
      ...session.user,
      profile,
      user_metadata: {
        ...session.user.user_metadata,
        ...profile
      }
    }
  }, [supabase])

  const getUser = useCallback(async (): Promise<UserWithProfile | null> => {
    // 验证会话
    const {
      data: { session },
      error: sessionError
    } = await supabase.auth.getSession()
    if (!session || sessionError) {
      clearUserCache()
      return null
    }

    // 检查缓存
    const cachedProfile = localStorage.getItem(CACHE_KEY)
    const cachedTime = localStorage.getItem(CACHE_TIME_KEY)

    if (cachedProfile && cachedTime) {
      const isValid = Date.now() - parseInt(cachedTime) < CACHE_DURATION
      if (isValid) {
        return {
          ...session.user,
          profile: JSON.parse(cachedProfile),
          user_metadata: {
            ...session.user.user_metadata,
            ...JSON.parse(cachedProfile)
          }
        }
      }
    }

    // 获取新数据
    const userData = await fetchUser()
    if (userData?.profile) {
      localStorage.setItem(CACHE_KEY, JSON.stringify(userData.profile))
      localStorage.setItem(CACHE_TIME_KEY, Date.now().toString())
    }

    return userData
  }, [fetchUser])

  const {
    data: user,
    isLoading,
    error
  } = useQuery({
    queryKey: ['user'],
    queryFn: getUser,
    staleTime: CACHE_DURATION,
    refetchOnWindowFocus: false,
    retry: (failureCount, error) => {
      if (error?.message?.includes('auth')) return false
      return failureCount < 3
    }
  })

  const refetch = () => queryClient.invalidateQueries({ queryKey: ['user'] })

  return {
    user,
    loading: isLoading,
    hasLogin: !!user,
    error,
    refetch
  }
}

export function useSignOut() {
  const supabase = useSupabase()
  const queryClient = useQueryClient()
  queryClient.invalidateQueries({ queryKey: ['token'] })

  return useCallback(async () => {
    await supabase.auth.signOut()
    queryClient.setQueryData(['user'], null)
    clearUserCache()
  }, [supabase, queryClient])
}
